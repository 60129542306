<template>
  <v-row>
    <v-col cols="12" lg="7" xl="6" class="primary d-none d-md-flex align-center justify-center backgroundImg">
      <v-container>
        <div class="pa-10">
          <v-row justify="center">
            <v-col cols="8" xl="5">
              <div class="d-flex justify-center">
                <img src="@/assets/images/logos/logo-vumetric-telus-white.svg" alt="Vumetric logo" />
              </div>
              <div class="d-flex justify-center">
                <v-btn class="mt-4 text-capitalize" x-large outlined color="white" @click="goToCorporateWebsite">
                  Learn More
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <div class="text-left">
                <div class="text-center">

                  <img
                    v-if="$route.name === 'Sub-SSO-Aws'"
                    justify-center
                    height="80px"
                    src="@/assets/images/logos/logo-aws-marketplace.svg"
                  />
                  <img v-else justify-center height="80px" src="@/assets/images/logos/logo-azure-marketplace.svg" />
                  <h4 class="mt-6 pb-4">Please enter your contact details</h4>

                </div>
                <!--<div v-if="newSubClient" class="d-flex align-center mb-10 mt-6">
                  <v-avatar color="background darken-1" class="mr-4" size="60">
                    <span class="primary--text text--lighten-2 text-h5 font-weight-bold text-capitalize">
                      {{ userName ? userName.split(" ").map((n) => n[0]).join("") : 'Fn' }}
                    </span>
                  </v-avatar>
                  <div class="text-left">
                    <p class="font-weight-medium ma-0">{{ userName ? userName : 'Your Fullname' }}</p>
                    <p class="body-2 text--disabled mb-2">
                      {{ email ? email : 'YourEmail@exemple.com' }}
                    </p>
                  </div>
                </div>-->
              </div>
              <div v-if="!newSubClient" class="mt-10 ml-6 text-center">
                <v-progress-circular :size="90" :width="7" color="primary" indeterminate>
                  <v-icon large class="animRotation">mdi-timer-sand-empty</v-icon>
                </v-progress-circular>
                <h4 class="mt-8">Onboarding ...</h4>
              </div>

              <v-form v-if="newSubClient" ref="form" v-model="valid" lazy-validation>
                <h5 class="title">User Account</h5>
                <v-card flat color="transparent">
                  <v-text-field
                    v-model="userName" label="Full name *"
                    :rules="[value => !!value || 'This field is required']" required dense outlined
                  />
                  <v-text-field
                    v-if="formAWS" v-model="email" type="email" label="E-mail *"
                    :rules="emailRules" required dense outlined
                  />
                  <v-text-field
                    v-if="formAWS" v-model="phone" label="Phone Number" type="phone" required dense
                    outlined
                  />

                  <!--<v-select
                    v-if="formAWS"
                    v-model="provider"
                    :items="[{ text: 'Azure', value: 'azure'}, { text: 'Internal', value: 'internal'}]"
                    label="Login Provider *"
                    :rules="[value => !!value || 'This field is required']"
                    dense
                    required
                    outlined
                  />-->

                  <h5 class="title">Your organization</h5>
                  <v-text-field
                    v-model="clientName" label="Organization Name *" class="mt-4"
                    :rules="[value => !!value || 'This field is required']" required dense outlined
                  />
                  <!--<v-text-field
                    v-model="clientShortName"
                    label="Short Organisation Name *"
                    :rules="[value => !!value || 'This field is required']"
                    required
                    dense
                    outlined
                  />-->

                  <v-autocomplete v-model="country" label="Country *" :items="countries" required dense outlined />

                  <v-select
                    v-model="organizationSize" :items="organizationSizeEmp" label="Number of Employees" dense
                    required outlined
                  />
                  <v-btn :disabled="isDiabledBtn()" color="primary" submit block depressed @click="callFuncNewSup">
                    Confirm Subscription
                  </v-btn>
                </v-card>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
    <snack-bar />
    <v-dialog v-model="isLock" :max-width="920" persistent>
      <v-card class="pa-6" flat>
        <v-card-text>
          <v-row>
            <v-spacer />
            <v-col cols="auto">
              <img width="300px" src="@/assets/images/illustrations/business_deal.svg">
            </v-col>
            <v-spacer />
          </v-row>
          <!--<v-row dense>
            <v-spacer />
            <v-col cols="auto">
              <h1>No Access</h1>
            </v-col>
            <v-spacer />
          </v-row>-->
          <v-row>
            <v-spacer />
            <v-col cols="auto">
              <div class="text-center">
                <h1>Thank you for subscribing!</h1>
                <div class="text-body-1 mb-4">Welcome to our enterprise PTaaS platform. In order to provide you with a
                  customized experience, our team will prepare a personalized proposal to initiate your first penetration
                  testing project. A member of our sales team will contact you shortly to guide you through the process.
                </div>
                <h4>Want to speed things up?</h4>
                <div class="text-body-1 mt-2">If you'd like to move forward more quickly, you can expedite the process by
                  using our simplified Penetration Testing Project Scoping Form. By providing us with specific information
                  about your project, we can quickly prepare a detailed proposal. Click the button below to get started:
                </div>
                <v-btn
                  class="mt-4 mb-4" depressed color="primary"
                  @click="goTo('https://www.vumetric.com/pentest-quote/?email=' + email + '&utm_source=ptaas&utm_medium=popup&utm_campaign=confirmation')"
                >CREATE MY FIRST PROJECT</v-btn>
                <div class="text-body-1 mt-4">
                  If you have any questions or need further assistance, <a href="#"
                    @click="goTo('https://www.vumetric.com/contact-us/')">our support team is ready to help</a>. Feel free
                  to contact us at any time!
                </div>
              </div>
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import User from '@/models/user'
import UserService from '@/services/user.service'
import SubscriptionsService from '@/services/subcriptions.service'
import SnackBar from '@/components/base/SnackBar'
import snackbarPlugin from '@/plugins/snackbar'
import { countries } from '@/data/common'
import kebabCase from "lodash/kebabCase"

export default {
  components: {
    SnackBar
  },
  data: () => ({
    user: new User('', ''),
    valid: false,
    newSubClient: false,
    formAWS: false,
    valuePW: String,
    clientRecToken: null,
    clientName: null,
    clientShortName: null,
    userName: null,
    email: null,
    phone: null,
    organizationSize: null,
    provider: null,
    country: null,
    isLock: false,
    countries,
    organizationSizeEmp: [
      { text: '1-25 employees', value: '1-25' },
      { text: '25-50 employees', value: '25-50' },
      { text: '50-100 employees', value: '50-100' },
      { text: '100-250 employees', value: '100-250' },
      { text: '500-1000 employees', value: '500-1000' },
      { text: '1000+ employees', value: '1000+' }
    ]
  }),
  watch: {
    clientName: {
      handler: function(newValue) {
        const slugedName = kebabCase(newValue);
        this.clientShortName = slugedName.toUpperCase()
      },
      deep: true
    }
  },
  created() {
    if (this.$route.path === '/landing/azure/') {
      this.newSubCheck()
    }
    if (this.$route.path === '/subscription/azure/activate') {
      this.valideSub({
        code: this.$route.query.code,
        state: this.$route.query.state,
        sessionState: this.$route.query.sessionState
      })
    }
    if (this.$route.path === '/subscription/aws/register') {
      this.clientRecToken = this.$route.query["x-amzn-marketplace-token"]
      this.newSubClient = true
      this.formAWS = true
    }
  },
  computed: {
    emailRules() {
      return [
        email => {
          if (!email) {
            return 'Email is required'
          }
          if (!/^[^@]+@[^@]+$/.test(email)) {
            return 'Invalid email address'
          }
          const domain = email.split('@')[1].split(".")[0]
          if (domain && ['edu', 'gmail', 'hotmail'].indexOf(domain) >= 0) {
            return 'Invalid email domain'
          }
          return true
        }
      ]
    }
  },
  methods: {
    slugifyAndUpperCase(str) {
      // Remplacer les espaces et les caractères spéciaux par des tirets
      // et convertir la chaîne en minuscules
      const slug = str.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

      // Convertir la chaîne en majuscules
      return slug.toUpperCase();
    },
    goTo(link) {
      if (confirm(`You will be redirected to an external page of this website at: ${link}`) === true) {
        window.location.href = link
      }
    },
    callFuncNewSup() {
      if (this.formAWS === true) {
        this.newClientAWS()
      } else {
        this.newClientAzure()
      }
    },
    valideSub(data) {
      SubscriptionsService.validateSubscription(
        data
      ).then(() => {
        this.$store.dispatch(
          'auth/ssoLoginSub'
        ).then(resp => {
          UserService.getProfile().then(profile => {
            this.$store.commit('auth/setProfile', profile)
            // this.$router.push("/dashboard")
            setTimeout(() => {
              this.$router.go()
            }, 700)
          })
        }).catch(() => {})
      }).catch(() => {})
    },
    newClientAWS() {
      SubscriptionsService.callToAWSMarketplace(
        {
          regToken: this.$route.query['x-amzn-marketplace-token'],
          companyName: this.clientName,
          contactPerson: this.userName,
          contactPhone: this.phone,
          contactEmail: this.email
        }
      ).then(() => {
        SubscriptionsService.createClientAWS(
          {
            client: {
              name: this.clientName,
              shortName: this.slugifyAndUpperCase(this.clientName),
              size: this.organizationSize,
              country: this.country
            },
            user: {
              email: this.email,
              fullName: this.userName,
              provider: null,
              phone: this.phone
            },
            token: this.$route.query['x-amzn-marketplace-token']
          },
          this.clientRecToken
        ).then(() => {
          setTimeout(() => {
            snackbarPlugin.showSuccess('Your user and customer account has been created')
          }, 500)
          this.isLock = true
        }).catch((err) => {
          if (err.response.data.detail) {
            snackbarPlugin.showError(err.response.data.detail)
          }
        })
      })
    },
    newClientAzure() {
      SubscriptionsService.postNewClientAzure(
        {
          client: {
            name: this.clientName,
            shortName: this.slugifyAndUpperCase(this.clientName),
            country: this.country,
            size: this.organizationSize
            // address: this.address,
            // city: this.city,
            // postalCode: this.postalCode,
            // phone: this.phone,
            // industry: this.industry,
            // status: this.status,
          },
          user: {
            // email: this.email,
            fullName: this.userName
          }
        },
        this.clientRecToken
      ).then(resp => {
        window.location.href = resp.data.authUrl
      })
    },
    isDiabledBtn() {
      return !(this.clientName && this.userName)
    },
    async newSubCheck() {
      await SubscriptionsService.postCheckSubscription({
        token: this.$route.query.token
      }).then(response => {
        if (response.data.authUrl) {
          window.location.href = response.data.authUrl
        }
        if (response.data.clientRegistrationToken) {
          this.clientRecToken = response.data.clientRegistrationToken
          this.email = response.data.userEmail
          this.newSubClient = true
        } else {
          this.$router.push('/login')
        }
      }).catch((err) => {
        if (err.response.data.detail) {
          snackbarPlugin.showError(err.response.data.detail)
        }
        this.$router.push('/login')
      })
    },
    goToCorporateWebsite() {
      window.location.href = 'https://www.vumetric.com'
    }
  }
}
</script>

<style lang="scss">
.backgroundImg {
  background-image: url("../../assets/images/background-hex.png");
}

.v-stepper__header {
  box-shadow: none !important;
}

.animRotation {
  animation: animR 1.5s infinite linear;
  position: relative;
}

@keyframes animR {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-359deg);
  }
}
</style>
